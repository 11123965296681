/* 集采相关接口 */
import request from '@/utils/request'

export function articleCateList(data) { // 文章分类列表
  return request({
    url: 'ArticleCate/getList',
    method: 'post',
    data
  })
}
export function articleList(data) { // 文章列表
  return request({
    url: 'Article/getList',
    method: 'post',
    data
  })
}
export function articleDetails(data) { // 文章详情
  return request({
    url: 'Article/read',
    method: 'post',
    data
  })
}
