<template>
    <div class="collect flex_jc">
      <!--导航列表-->
      <div class="navigation">
       <div class="title">集采服务</div>
        <ul>
            <li :class="articleTypeIndex === index ? 'articleAction' : ''" @click="articleTypeSwitch(item.id, index)" v-for="(item, index) in articleTypeList" :key="item.id"><span>{{ item.name }}</span></li>
        </ul>
      </div>
      <!--文章模块-->
      <div class="article">
        <!--搜索框-->
        <div class="search">
          <Search :searchType="'collect'" @searchClick="searchClick" />
        </div>
        <!--文章列表-->
        <div class="list">
          <div class="list-li flex_ac" @click="details(item.id)" v-for="(item) in articleDataList" :key="item.id">
            <!-- <img :src="item.pic" alt="文章图片"> -->
            <div class="info flex_ac">
              <div class="left flex_ac">
                <h3>{{ item.name }}</h3>
              </div>
              <div>
                <p>{{ item.intro }}</p>
              </div>
              <!-- <div class="right flex_ac">
                <div>
                  <p class="time">{{ item.month }}</p>
                  <p>{{ item.year }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <el-empty v-if="!articleDataList.length"></el-empty>
        <!--分页-->
        <div class="padding">
          <Pagination v-if="articleDataList.length" :pageSize="paddingObj.psize" :total="paddingObj.total" @changeCurrentPage="changeCurrentPage" />
        </div>
      </div>
    </div>
</template>

<script>
import { articleCateList, articleList } from '@/api/collect/collect'
import Search from '@/components/common/search'
import Pagination from '@/components/common/pagination'
export default {
  name: 'collect',
  components: {
    Search,
    Pagination
  },
  data() {
    return {
      articleTypeIndex: 0, // 文章类型
      articleTypeList: ['集采政策解读', '集采投标价格分析'], // 文章类型列表
      articleDataList: [], // 文章列表
      paddingObj: { // 分页配置
        total: 0,
        page: 1,
        psize: 10,
        article_cate_id: null,
        keyword: ''
      }
    }
  },
  created() {
    this.getarticleCateList()
  },
  methods: {
    searchClick(keyword) {
      this.paddingObj.keyword = keyword
      this.getArticleList()
    },
    articleTypeSwitch(id, index) { // 文章分类切换
      this.articleTypeIndex = index;
      this.paddingObj.article_cate_id = this.$route.query.id || id
      this.$route.query.id = ''
      // this.paddingObj.article_cate_id = this.$route.query.id
      this.paddingObj.page = 1;
      this.getArticleList()
    },
    getarticleCateList() { // 获取文章分类列表
      articleCateList().then(res => {
        this.articleTypeList = res.data;
        this.paddingObj.article_cate_id = this.$route.query.id || res.data[0].id
        this.$route.query.id = ''
        // this.paddingObj.article_cate_id = this.$route.query.id
        this.getArticleList()
      })
    },
    getArticleList() { // 文章列表
      articleList(this.paddingObj).then(res => {
        this.paddingObj.total = res.data.count;
        this.articleDataList = res.data.list.map(item => {
          const time = item.create.split('-');
          item.month = `${time[1]}-${time[2]}`
          item.year = time[0]
          return item;
        });
      })
    },
    changeCurrentPage(value) { // 分页页码变化
      this.paddingObj.page = value;
      this.getArticleList()
    },
    details(id) { // 文章详情
      this.$router.push({path: '/collectionDetails', query: { id }})
    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  .collect {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    .navigation {
      width: 240px;
      // height: 178px;
      margin-right: 10px;
      text-align: center;
      font-size: 16px;
      .title {
        background-color: #1166CF;
        height: 48px;
        line-height: 48px;
        color: #fff;
      }
      .articleAction {
        color: #1166CF;
      }
      ul li {
        height: 65px;
        line-height: 65px;
        background-color: #fff;
        span {
          cursor: pointer;
          &:hover {
            color: #1166CF;
          }
        }
      }
    }
    .article {
      flex: 1;
      .search {
        height: 100px;
        background: #fff;
        padding: 26px 41px;
        box-sizing: border-box;
        margin-bottom: 20px;
        box-sizing: border-box;
      }
      .list {
        // padding: 18px;
        // background-color: #fff;
        .list-li {
          // padding: 18px 0 18px 16px;
          // padding-bottom: 18px;
           padding: 18px;
          margin-bottom: 12px;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #fff;
          &:hover {
            border: 1px solid #1166CF;
          }
          img {
            width: 184px;
            height: 144px;
          }
          .info {
            flex: 1;
            padding: 0 24px;
            h3 {
              font-size: 18px;
              color: #333;
              // margin-bottom: 22px;
            }
            p {
              font-size: 14px;
              color: #666;
            }
            .left {
              flex: 1;
              div {
                padding: 10px 0;
                border-right: 1px solid #eee;
              }
              p {
                font-size: 14px;
                color: #666;
                padding-right: 40px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;

              }
            }
            .right {
              width: 156px;
              div {
                width: 100%;
                text-align: center;
              }
              .time {
                font-size: 24px;
                color: #333;
                margin-bottom: 13px;
              }
              p {
                font-size: 16px;
                color: #999;
              }
            }
          }
        }
      }
      .padding {
        margin: 30px 0;
      }
    }
  }
</style>
